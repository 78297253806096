<template>
  <div>{{ value ? this.$proSmart.common.getFormattedDate(value) : "" }}</div>
</template>

<script>
export default {
  name: "ProReportDateTimeNullTdField",
  props: {
    value: null,
  },
};
</script>
